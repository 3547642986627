import VueGtag from 'vue-gtag';
import { createApp } from 'vue';

import { createPinia, setActivePinia } from 'pinia';
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import piniaPluginResetStore from '@/stores/plugins/resetStore';
import { PiniaSharedState } from 'pinia-shared-state';
import App from './App.vue';
import router from './router';
import config from '@/config';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './styles/decisioninsite.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as labsComponents from 'vuetify/labs/components';
import * as directives from 'vuetify/directives';
import { mdi, aliases } from 'vuetify/iconsets/mdi';
import { fa } from 'vuetify/iconsets/fa';
import { di } from '@/iconsets/di';
import 'vue-search-select/dist/VueSearchSelect.css';
import './styles/app.css';
import './styles/theme-b.css';

import Notifications from '@kyvg/vue3-notification';

import useAuthStore from '@/stores/auth';
import { trackRouter } from 'vue-gtag-next';

import { vMaska } from 'maska';

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  },
};

import { initConfig } from './config';
import { initGeos } from './config/geos';

(async () => {
  await initConfig();
  await initGeos();

  const vuetify = createVuetify({
    components: {
      ...components,
      ...labsComponents,
    },
    directives,
    defaults: {
      global: {
        density: 'compact',
      },
      VTooltip: {
        maxWidth: 250,
        location: 'top',
      },
      VTextField: {
        variant: 'underlined',
      },
      VSelect: {
        variant: 'underlined',
      },
      VAutocomplete: {
        variant: 'underlined',
      },
      VTextarea: {
        variant: 'underlined',
      },
      VBtn: {
        density: 'default',
      },
      VChip: {
        density: 'default',
      },
      VDataTable: {
        mobile: false,
      },
      VDataTableServer: {
        mobile: false,
      },
      VDataTableVirtual: {
        mobile: false,
      },
    },

    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
        fa,
        di,
      },
    },
    theme: {
      defaultTheme: 'myCustomTheme',
      themes: {
        myCustomTheme: {
          dark: false,
          colors: {
            primary: '#102445',
          },
        },
      },
    },
  });

  const pinia = createPinia();
  pinia.use(piniaPluginPersistedState);
  pinia.use(piniaPluginResetStore);
  pinia.use(
    PiniaSharedState({
      enable: false,
      type: 'localstorage',
    }),
  );
  // Activate Pinia so we can use AuthStore to get the current user for GA logging
  setActivePinia(pinia);
  const store = useAuthStore();

  // Google Analytics router traffic tracking
  trackRouter(router);

  createApp(App)
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(VueQueryPlugin, vueQueryPluginOptions)
    .use(VueGtag, {
      config: {
        id: config.googleTagCode,
        params: {
          user_id: store.user,
          anonymize_ip: true, // https://support.google.com/analytics/answer/2763052
        },
      },
    })
    .use(Notifications)
    .directive('maska', vMaska)
    .mount('#app');
})();
