<template>
  <notifications />
  <router-view />
</template>

<script setup lang="ts">
import { notify } from '@kyvg/vue3-notification';
import { useRouter } from 'vue-router';
import AuthService from '@/services/auth.service';

const router = useRouter();
import fetchIntercept from 'fetch-intercept';
import { publicUrls } from './constants/urls';
fetchIntercept.register({
  response: response => {
    const status = response.status;
    if (status === 401) {
      AuthService.clearIsImpersonated();
      //@todo tr to refresh token, if ok, re-run response.request
      //in case of error -> redirect to login
      router.push(publicUrls.login);
    }
    if (status === 503) {
      router.push(publicUrls.unavailable);
    }
    if (status === 206) {
      //Partial Content (still successful, but show warning message)
      response.json().then(res => {
        notify({
          type: 'error', //Change to a warning type later
          text: res.message ?? 'Something went wrong',
          duration: 8000,
        });
      });
    }
    if ([404, 500].includes(status)) {
      response.json().then(res => {
        notify({
          type: 'error',
          text: res.message ?? 'Something went wrong',
          duration: 5000,
        });
      });
    }
    return response;
  },
});
</script>
