import { v4 as uuidv4 } from 'uuid';
import mapboxgl from 'mapbox-gl';
import { parse, stringify } from 'wellknown';

import { isValidGeos } from './makeValid';
import { getFeatureCoordinates, unionFeatures } from '@/utils/shapes/geometry';

import {
  defaultShapeLabelProperties,
  defaultShapeProperties,
  shapeNameTypeMap,
  shapeSettings,
  shapeTypeAndNameMap,
  shapesAllowedToUnion,
} from '@/constants/shape';
import { DRAWING_MODES, IEventShape, IShape, SHAPE, DRAW_SHAPE_DATA_TYPE, GEOMETRY_TYPE } from '@/types/shape';

import type MapboxDraw from '@mapbox/mapbox-gl-draw';
import type { Feature, Polygon, MultiPolygon, GeometryCollection, Point } from '@turf/turf';
import type { TMap } from '@/types/map';
import useMapStore from '@/stores/map';
import useSelectionStore from '@/stores/selection';

export const toggleShapeSelection = ({
  drawControl,
  shapeId,
  shapeSelected,
}: {
  drawControl: MapboxDraw;
  shapeId: string;
  shapeSelected: boolean;
}): void => {
  const selectedIds = drawControl.getSelectedIds();

  if (shapeSelected) {
    const filteredSelectedShapes = selectedIds.filter(id => id !== shapeId);
    drawControl.changeMode(DRAWING_MODES.simpleSelect, { featureIds: filteredSelectedShapes });
    return;
  }

  // If any shape is already selected add a new shape to the simple_select mode
  if (selectedIds.length) {
    drawControl.changeMode(DRAWING_MODES.simpleSelect, { featureIds: [...selectedIds, shapeId] });
    return;
  }

  // If no selected shapes, direct_select single shape
  drawControl?.changeMode(DRAWING_MODES.directSelect, { featureId: shapeId });
};

export const unselectMutedShape = ({ drawControl, shapeId }: { drawControl: MapboxDraw; shapeId: string }): void => {
  const selectedIds = drawControl.getSelectedIds();
  const filteredSelectedShapes = selectedIds.filter(id => id !== shapeId);

  drawControl.changeMode(DRAWING_MODES.simpleSelect, { featureIds: filteredSelectedShapes });
};

export const allowUnionShapes = (drawControl: MapboxDraw | null): boolean => {
  const features = drawControl?.getSelected().features as IShape[] | undefined;
  if (!features) return false;

  const allTypesPossibleToUnion = features.every(feature => shapesAllowedToUnion.includes(feature.properties.type));
  return allTypesPossibleToUnion && features.length >= 2;
};

export const unionShapes = (drawControl: MapboxDraw): { createdFeature: IEventShape; deletedFeatures: string[] } => {
  const features = drawControl.getSelected().features as Feature<Polygon | MultiPolygon>[];
  const featureIds = features.filter(feature => feature.id).map(feature => feature.id?.toString()) as string[];

  let unionizedFeature = features[0];
  features.forEach(feature => {
    unionizedFeature = unionFeatures(unionizedFeature, feature) as Feature<Polygon | MultiPolygon>;
  });

  const drawFeature = { ...unionizedFeature, properties: features[0].properties, id: uuidv4() };

  drawControl.add(drawFeature);
  return { deletedFeatures: featureIds, createdFeature: drawFeature as IEventShape };
};

export const getShapeVertices = (map: TMap | null, shapeId: string): Feature<Point>[] => {
  return (map
    ?.queryRenderedFeatures()
    .filter(feature => feature.properties?.meta === 'vertex' && feature.properties.parent === shapeId) ||
    []) as Feature<Point>[];
};

export const zoomToShapes = ({
  map,
  drawControl,
  shapeIds,
}: {
  map: TMap;
  drawControl: MapboxDraw;
  shapeIds: string[];
}) => {
  const bounds = new mapboxgl.LngLatBounds();

  shapeIds.forEach(shapeId => {
    const shape = drawControl.get(shapeId) as IShape;
    const coordinates = getFeatureCoordinates(shape) as [number, number][];

    coordinates.forEach(coordinate => {
      bounds.extend(coordinate);
    });
  });

  map.fitBounds(bounds, { padding: 60 });
};

export const parseWkt = (data: string) => {
  return parse(data);
};

export const stringifyWkt = (geojson: any) => {
  return stringify(geojson);
};

const parseGeoJson = (geoJson: string) => {
  const json = JSON.parse(geoJson);

  // Old-style crs member is not recommended and can potentially lead to errors
  if (json.crs) delete json.crs;
  return json;
};

export const getShapeGeojson = (dataType: DRAW_SHAPE_DATA_TYPE, data: string): GeometryCollection | null => {
  try {
    return dataType === DRAW_SHAPE_DATA_TYPE.WKT ? parseWkt(data) : parseGeoJson(data);
  } catch (error) {
    return null;
  }
};

const getShapeTypeFromMode = (mode: DRAWING_MODES): SHAPE => {
  switch (mode) {
    case DRAWING_MODES.polygon:
    case DRAWING_MODES.freeHand:
      return SHAPE.Polygon;
    case DRAWING_MODES.rectangle:
      return SHAPE.Rectangle;
    case DRAWING_MODES.circle:
      return SHAPE.Circle;
    case DRAWING_MODES.lineDistance:
      return SHAPE.LineDistance;
    case DRAWING_MODES.point:
      return SHAPE.Point;
    default:
      return SHAPE.Polygon;
  }
};

const getShapeTypeFromGeometry = (geometryType: GEOMETRY_TYPE): SHAPE => {
  switch (geometryType) {
    case GEOMETRY_TYPE.polygon:
    case GEOMETRY_TYPE.multiPolygon:
      return SHAPE.Polygon;

    case GEOMETRY_TYPE.lineString:
    case GEOMETRY_TYPE.multiLineString:
      return SHAPE.LineDistance;

    case GEOMETRY_TYPE.point:
    case GEOMETRY_TYPE.multiPoint:
      return SHAPE.Point;

    default:
      return SHAPE.Polygon;
  }
};

export const getShapeAfterCreatedFromData = (feature: IEventShape): IShape => {
  const valid = isValidGeos(feature);
  const shapeType = getShapeTypeFromGeometry(feature.geometry.type as GEOMETRY_TYPE);
  const labelPosition: [number, number] = shapeType === SHAPE.Point ? [0, 1] : [0, 0];
  const name = String(feature.properties?.Name || shapeTypeAndNameMap[shapeType]);
  return {
    ...feature,
    properties: { ...defaultShapeProperties, ...feature.properties, type: shapeType, invalid: !valid },
    labelProperties: {
      ...defaultShapeLabelProperties,
      name,
      position: labelPosition,
      invalid: !valid,
    },
    settings: shapeSettings,
  };
};

export const getShapeAfterUnion = (feature: IEventShape): IShape => {
  const valid = isValidGeos(feature);
  return {
    ...feature,
    properties: { ...defaultShapeProperties, ...feature.properties, invalid: !valid },
    labelProperties: { ...defaultShapeLabelProperties, name: shapeNameTypeMap[DRAWING_MODES.polygon], invalid: !valid },
    settings: shapeSettings,
  };
};

export const getShapeAfterCreation = (evt: MapboxDraw.DrawCreateEvent, mode = DRAWING_MODES.polygon): IShape => {
  const feature = evt.features[0] as IShape;
  const valid = isValidGeos(feature);
  const shapeType = getShapeTypeFromMode(mode);
  const labelPosition: [number, number] = mode === DRAWING_MODES.point ? [0, 1] : [0, 0];

  const labelName = feature.labelProperties?.name ?? shapeNameTypeMap[mode];
  const labelProperties = Object.assign(
    {},
    defaultShapeLabelProperties,
    { name: labelName, position: labelPosition, invalid: !valid },
    feature.labelProperties,
  );
  return {
    ...feature,
    properties: { ...defaultShapeProperties, ...feature.properties, type: shapeType, invalid: !valid },
    labelProperties,
    settings: shapeSettings,
  };
};

function tryToAddFeature(feature: GeoJSON.Feature): string[] | undefined {
  const mapStore = useMapStore();

  try {
    return mapStore.drawControl?.add(feature);
  } catch (error) {
    return undefined;
  }
}

export function createShapesFromGeometry(collection: GeoJSON.Feature) {
  const mapStore = useMapStore();
  const selectionStore = useSelectionStore();
  selectionStore.clearShapesHistory();

  const featureIds = tryToAddFeature(collection);
  if (!featureIds) return;

  const createdShapes = featureIds.map(featureId => {
    const createdFeature = mapStore.drawControl?.get(featureId) as IEventShape;
    return getShapeAfterCreatedFromData(createdFeature);
  });
  selectionStore.bulkUpdateShapes({ created: createdShapes });

  if (featureIds && mapStore.drawControl && mapStore.map) {
    zoomToShapes({ drawControl: mapStore.drawControl, map: mapStore.map, shapeIds: featureIds });
  }
}
