import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '@/views/auth/Login.vue';
import RegistrationView from '@/views/auth/Registration.vue';
import { publicUrls, dashboardUrls } from '@/constants/urls';
import authGuard from './authGuard';
import publicGuard from './publicGuard';

const routes: Array<RouteRecordRaw> = [
  {
    path: publicUrls.home,
    name: 'home',
    redirect: {
      name: 'map',
    },
  },
  {
    path: publicUrls.login,
    name: 'login',
    beforeEnter: publicGuard,
    component: LoginView,
  },
  {
    path: publicUrls.registration,
    name: 'registration',
    component: RegistrationView,
  },
  {
    path: publicUrls.preRegistration,
    name: 'start-registration',
    component: () => import('../views/auth/StartRegistration.vue'),
  },
  {
    path: publicUrls.preLogin,
    name: 'start-login',
    component: () => import('../views/auth/StartLogin.vue'),
  },
  {
    path: publicUrls.postLogin,
    name: 'post-login',
    component: () => import('../views/auth/PostLogin.vue'),
  },
  {
    path: publicUrls.postRegistration,
    name: 'post-registration',
    component: () => import('../views/auth/PostRegistration.vue'),
  },
  {
    path: publicUrls.postLogout,
    name: 'post-logout',
    component: () => import('../views/auth/PostLogout.vue'),
  },
  {
    path: publicUrls.unavailable,
    name: 'unavailable',
    component: () => import('../views/error/Unavailable.vue'),
  },
  {
    path: publicUrls.userApproval,
    name: 'user-approval',
    component: () => import('../views/admin/UserApprovalPage'),
  },
  {
    path: '/map/:study?',
    name: 'map',
    component: () => import('../views/map/index.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredAcl: [],
      requiredAnyAcl: [],
      redirectOnFail: publicUrls.login,
      saveUrlOnFail: true,
    },
    children: [
      {
        path: '',
        name: 'default',
        component: () => import('../views/map/Home.vue'),
      },
      {
        path: dashboardUrls.home,
        component: () => import('../views/map/Home.vue'),
      },
      {
        path: dashboardUrls.view,
        component: () => import('../views/map/Views.vue'),
      },
      {
        path: dashboardUrls.layers,
        component: () => import('../views/map/Layers.vue'),
      },
      {
        path: dashboardUrls.shapes,
        component: () => import('../views/map/Shapes.vue'),
      },
      {
        path: dashboardUrls.plotting,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: [],
          requiredAnyAcl: ['mapp', 'manc'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/Plotting.vue'),
      },
      {
        path: dashboardUrls.demographics,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: [],
          requiredAnyAcl: ['masa', 'madr', 'maar', 'manr', 'matm', 'maos'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/Demographics.vue'),
      },
      {
        path: dashboardUrls.recent,
        component: () => import('../views/map/Recent.vue'),
      },
      {
        path: dashboardUrls.help,
        component: () => import('../views/map/Help.vue'),
      },
      {
        path: dashboardUrls.generateReports,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: ['masa', 'marr'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/GenerateReports.vue'),
      },
      {
        path: dashboardUrls.peoplePlot,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: [],
          requiredAnyAcl: ['mapp', 'manc'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/PeoplePlot.vue'),
      },
      {
        path: dashboardUrls.thematicMap,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: ['matm'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/ThematicMap.vue'),
      },
      {
        path: dashboardUrls.neighborCenter,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: ['manc'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/NeighborCenter.vue'),
      },
      {
        path: dashboardUrls.opportunityScan,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: ['maos'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/OpportunityScan.vue'),
      },
      {
        path: dashboardUrls.purchaseAddressList,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: ['maal'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/PurchaseAddressList.vue'),
      },
      {
        path: dashboardUrls.exportDownloadMap,
        beforeEnter: authGuard,
        meta: {
          requiredAcl: ['masa'],
          redirectOnAuthorizationFail: publicUrls.noacl,
          saveUrlOnFail: true,
        },
        component: () => import('../views/map/ExportDownloadMap.vue'),
      },
    ],
  },
  {
    path: '/managed-window/oppscan',
    name: 'managed-oppscan',
    component: () => import('../views/managed-window/oppscan.vue'),
  },
  {
    path: '/no-permissions-error',
    name: 'no-permissions-error',
    component: () => import('../views/error/NoPermissions.vue'),
  },
  {
    path: '/no-agencies-error',
    name: 'no-agencies-error',
    component: () => import('../views/error/NoAgencies.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/error/PageNotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
