import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "copy" }

import bg from '@/assets/img/MI-bg.png';

export default /*@__PURE__*/_defineComponent({
  __name: 'AuthLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(bg),
      alt: "",
      class: "bg"
    }, null, 8, _hoisted_2),
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(" © Copyright " + _toDisplayString(new Date().getFullYear()) + " ACS Technologies  |  ", 1),
      _cache[0] || (_cache[0] = _createElementVNode("a", {
        href: "https://help.acst.com/?resourceId=privacy.policy",
        target: "_blank"
      }, "Privacy Policy", -1)),
      _cache[1] || (_cache[1] = _createTextVNode("  |  ")),
      _cache[2] || (_cache[2] = _createElementVNode("a", {
        href: "https://help.acst.com/?resourceId=terms",
        target: "_blank"
      }, "Terms of Service", -1))
    ])
  ]))
}
}

})